import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import rootWindow from "window-or-global";

// import Agile_API from "../../../js/agile-min.js";
import "./css/agile-form-min.css";

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      agile_load_form_fields: {},
      agile_synch_form_v5: {}
    };

    this.toggle = this.toggle.bind(this);
    this.submit_agile_form = this.submit_agile_form.bind(this);
  }
  submit_agile_form() {
    this.state.agile_load_form_fields();
    this.state.agile_synch_form_v5(this);
  }

  componentDidMount() {
    // if (typeof window !== `undefined`) {
    this.setState({
      agile_load_form_fields: rootWindow._agile_load_form_fields,
      agile_synch_form_v5: rootWindow._agile_load_form_fields
    });
    // this.state.agile_load_form_fields();
    // }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  render() {
    return (
      <section
        className="bg-primary py-5"
        id="free"
        ref={section => {
          this.free = section;
        }}
      >
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Consultation Request</ModalHeader>
          <ModalBody>
            <div className="text-center">
              <h2>Schedule Your <br />Free Consultation</h2>
              <p>We love shiny new projects, challenges of all sizes, and simply talking business.  Let&apos;s get together and make something special.</p>
            </div>
            <iframe src="//mautic.jetsquared.com/form/1" width="100%" height="600"><p>Your browser does not support iframes.</p></iframe>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <h1 className="section-heading text-white mb-4">
                Let&apos;s Meet
                <span className="d-none d-sm-block">&emsp;+&emsp;</span>
                <span className="spanTable">
                  <span className="text-center d-block d-sm-none">+</span>
                </span>
                Free Consultation
              </h1>
              <h4 className="text-faded mb-4">
                <FontAwesomeIcon icon="coffee" />
                <span className="d-none d-sm-block">&emsp;+&emsp;</span>
                <span className="spanTable">
                  <span className="d-block d-sm-none">+</span>
                </span>
                There&apos;s nothing like a face to face.
              </h4>
              <hr className="w50 light my-4" />
              <p className="text-faded mb-2">
                Let&apos;s face it{" "}
                <small>
                  (pun intended{" "}
                  <span
                    role="img"
                    aria-label="grinning face with tightly closed eyes"
                  >
                    😆
                  </span>
                  ),{" "}
                </small>
                challenges in business are multifaceted and often affect many
                aspects of your day to day operations.{" "}
              </p>
              <p className="text-faded mb-4">
                This is why we believe solutions crafted through dialogue are
                sustainable, effective, and have your future business goals in
                mind.
              </p>
              <Button className="btn btn-light btn-xl" onClick={this.toggle}>
                Schedule a meeting!
              </Button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
