import React, { Component } from "react";
import jetsquared_White from "./img/jetsquared_White.png";

export default class Header extends Component {
  render() {
    return (
      <header className="masthead home_img text-white d-flex">
        <div className="container my-auto">
          <div className="row">
            <div className="col-md-7 mx-auto">
              <h1 className="hero">
                Personalized Approach
                <hr className="w50 light left" />
                Solutions that Scale with You
              </h1>
              {/* <p>Building solutions that work for you.</p> */}
            </div>
            <div className="img-div col-md-5 mx-auto mt-3">
              <img
                className="hero-image"
                src={jetsquared_White}
                alt="jetsquared_logo"
              />
            </div>
            <hr />
          </div>
        </div>
        <div className="next-section-arrow" />
      </header>
    );
  }
}
