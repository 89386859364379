import React from "react";
import PropTypes from "prop-types";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLaptop,
  faCoffee,
  faCaretDown,
  faCaretRight,
  faAngleDown,
  faAngleRight,
  faChevronDown,
  faChevronRight,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
import "../scss/creative.scss";

library.add(
  faAngleDown,
  faAngleRight,
  faCaretDown,
  faCaretRight,
  faChevronDown,
  faChevronRight,
  faCoffee,
  faLaptop,
  faArrowRight
);

import Home from "./Home";

const App = () => <Home />;

App.propTypes = {
  children: PropTypes.node
};

export default App;
