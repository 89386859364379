import React, { Component } from "react";
// import win from "window-or-global";

import About from "./components/About";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
// import Navigation from "../../Components/Navigation";
// import Portfolio from "./components/Portfolio";
import Services from "./components/Services";
import ReactGA from "react-ga";

export default class Home extends Component {
  componentDidMount() {
    ReactGA.initialize("UA-131334320-1");
  }
  render() {
    return (
      <div id="page-top">
        <Navigation />
        <Header />
        <About />
        <Services />
        {/* <Portfolio /> */}
        <Contact />
      </div>
    );
  }
}
