import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardBody,
  CardDeck,
  CardImg,
  CardText,
  CardTitle,
  Collapse
} from "reactstrap";
import code_security from "./img/code_security.png";
import tech_stack300 from "./img/tech_stack300.png";
import serverWCodeSquare_300 from "./img/serverWCodeSquare_300.png";

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      webCollapse: true,
      webRightArrow: false,
      customCollapse: false,
      customRightArrow: true,
      hostingCollapse: true,
      hostingRightArrow: false,
      managedCollapse: false,
      managedRightArrow: true
    };
  }
  toggle(target) {
    if (target === "webCollapse") {
      this.setState({
        webCollapse: !this.state.webCollapse,
        customCollapse: false,
        customRightArrow: true,
        webRightArrow: this.state.webCollapse
      });
    } else if (target === "customCollapse") {
      this.setState({
        webCollapse: false,
        webRightArrow: true,
        customCollapse: !this.state.customCollapse,
        customRightArrow: this.state.customCollapse
      });
    }

    if (target === "hostingCollapse") {
      this.setState({
        hostingCollapse: !this.state.hostingCollapse,
        managedCollapse: false,
        managedRightArrow: true,
        hostingRightArrow: this.state.hostingCollapse
      });
    } else if (target === "managedCollapse") {
      this.setState({
        hostingCollapse: false,
        hostingRightArrow: true,
        managedCollapse: !this.state.managedCollapse,
        managedRightArrow: this.state.managedCollapse
      });
    }
  }
  render() {
    return (
      <section id="services" className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">At Your Service</h2>
              <hr className="w50 my-4" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-1" />
            <CardDeck className="card-deck col-10">
              <Card className="card-shadow">
                <CardImg
                  top
                  className="bg-light"
                  src={tech_stack300}
                  alt="Card cap"
                />
                <CardBody className="p-0 pt-3">
                  <CardTitle
                    className="bottom-border"
                    onClick={() => this.toggle("webCollapse")}
                  >
                    Websites
                    <div className="float-right">
                      <FontAwesomeIcon
                        icon="angle-down"
                        hidden={this.state.webRightArrow}
                      />
                      <FontAwesomeIcon
                        icon="angle-right"
                        hidden={!this.state.webRightArrow}
                      />
                    </div>
                  </CardTitle>
                  <Collapse isOpen={this.state.webCollapse}>
                    <CardText>
                      Every site <em>should</em> be different... That&apos;s why
                      every site we make is!
                    </CardText>
                    <CardText className="mb-3">
                      We utilize current web and security standards. We strive
                      to create modern, dynamic, highly customizable websites,
                      while simultaneously prioritizing efficiency - for you!
                    </CardText>
                  </Collapse>
                  <CardTitle
                    className="bottom-border"
                    onClick={() => this.toggle("customCollapse")}
                  >
                    Custom Projects
                    <div className="float-right">
                      <FontAwesomeIcon
                        icon="angle-down"
                        hidden={this.state.customRightArrow}
                      />
                      <FontAwesomeIcon
                        icon="angle-right"
                        hidden={!this.state.customRightArrow}
                      />
                    </div>
                  </CardTitle>
                  <Collapse isOpen={this.state.customCollapse}>
                    <CardText>
                      We produce solutions to automate processes for projects
                      big and small alike. We are results-driven and
                      process-oriented, which means we deliver solutions that
                      save you time and money.
                    </CardText>
                    <hr
                      className="mr-5 ml-5"
                      style={{ border: "#dfdfdf 1px solid" }}
                    />
                    <CardText>
                      <b>Process Automation Example:</b>
                      <br />
                      <span className="text-center">
                        <em>Handwritten Forms</em>
                      </span>
                      <span className="pb-1">
                        How many times have we all attempted to read a scrawled,
                        practically illegible paper form?
                      </span>
                      <span>
                        <FontAwesomeIcon icon="arrow-right" size="sm" /> We
                        transform the paper form into PDFs automatically
                        delivered straight to your inbox.
                      </span>
                    </CardText>
                  </Collapse>
                </CardBody>
              </Card>
              <Card className="card card-shadow">
                <div className="img-container">
                  <CardImg
                    top
                    src={serverWCodeSquare_300}
                    alt="Card cap"
                    width="200"
                  />
                  <div className="bkg-overlay top-right">
                    <h3>VPS Hosting</h3>
                    <h5 className="text-right">
                      $20 / month
                      <br />
                      99.99% uptime
                    </h5>
                  </div>
                </div>
                <CardBody className="p-0 pt-3">
                  <CardTitle
                    className="bottom-border"
                    onClick={() => this.toggle("hostingCollapse")}
                  >
                    Hosting
                    <div className="float-right">
                      <FontAwesomeIcon
                        icon="angle-down"
                        hidden={this.state.hostingRightArrow}
                      />
                      <FontAwesomeIcon
                        icon="angle-right"
                        hidden={!this.state.hostingRightArrow}
                      />
                    </div>
                  </CardTitle>
                  <Collapse isOpen={this.state.hostingCollapse}>
                    <CardText className="mb-3">
                      <span>
                        We utilize the best servers the market has to offer. We
                        host our sites on those very same servers!
                      </span>
                    </CardText>
                  </Collapse>
                  <CardTitle
                    className="bottom-border"
                    onClick={() => this.toggle("managedCollapse")}
                  >
                    Fully Managed
                    <div className="float-right">
                      <FontAwesomeIcon
                        icon="angle-down"
                        hidden={this.state.managedRightArrow}
                      />
                      <FontAwesomeIcon
                        icon="angle-right"
                        hidden={!this.state.managedRightArrow}
                      />
                    </div>
                  </CardTitle>
                  <Collapse isOpen={this.state.managedCollapse}>
                    <CardText className="pb-2">
                      If anything happens to your server, we are the FIRST to
                      know. No need to call your uncle&apos;s nephew&apos;s
                      cousin&apos;s roommate{" "}
                      <span role="img" aria-label="squint-smiley">
                        😆
                      </span>
                      . Fully Managed means performance optimized servers,
                      regularly updated with the latest software and security
                      standards.
                    </CardText>
                    <CardText className="mb-3">
                      <b>Confidence</b>: We refund any unscheduled server
                      downtime.
                    </CardText>
                  </Collapse>
                  <CardText className="text-center">
                    <a
                      href="/Hosting"
                      role="button"
                      className="btn btn-info disabled"
                    >
                      Learn More
                      <br />
                      <small>Page Coming Soon</small>
                    </a>
                  </CardText>
                </CardBody>
              </Card>
              <Card className="card-shadow">
                <CardImg top src={code_security} alt="Card security" />
                <CardBody className="p-0 pt-3">
                  <CardTitle
                    className="bottom-border"
                    onClick={() => this.toggle("secureFocusCollapse")}
                  >
                    Focus on Security
                  </CardTitle>
                  <CardText>
                    <span className="pb-3">
                      <b>FREE</b> SSL Certificates.
                    </span>
                    <span>Every solution we build is focused on security.</span>
                    <span>
                      <br />
                      <small>More information to come.</small>
                    </span>
                  </CardText>
                </CardBody>
              </Card>
            </CardDeck>
          </div>
        </div>
      </section>
    );
  }
}
