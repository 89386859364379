import React, { Component } from "react";

export default class Contact extends Component {
  render() {
    return (
      <section id="contact" className="py-5 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <h2 className="section-heading">Let&apos;s Get In Touch!</h2>
              <hr className="w50 my-4" />
              <p className="mb-5">
                Ready to start your next project with us? That&apos;s great!
                Give us a&nbsp;<a href="tel:4843010080">call</a> or send us
                an&nbsp;<a href="mailto:info@jetsquared.com">email</a> and we
                will get back to you as soon as possible!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 ml-auto text-center">
              <a href="tel:4843010080">
                <i className="fas fa-phone fa-3x mb-3 sr-contact-1" />
              </a>
              <p className="text-center">
                <small>call or TEXT</small>
                <br />
                <a href="tel:4843010080" className="text-primary">
                  (484) 301-0080
                </a>
                <br />
              </p>
            </div>
            <div className="col-lg-4 mr-auto text-center">
              <a href="mailto:info@jetsquared.com?body=Hi%20JET%20Squared,%0A%0AI%20have%20a%20(?project?%20or%20?idea?)%20that%20I'd%20like%20to%20speak%20to%20you%20further%20about.%0A%0A&subject=I%20want%20to%20learn%20more%20about%20you're%20business">
                <i className="fas fa-envelope fa-3x mb-3 sr-contact-2" />
              </a>
              <p>
                <small>e-mail us at</small>
                <br />
                <a
                  href="mailto:info@jetsquared.com?body=Hi%20JET%20Squared,%0A%0AI%20have%20a%20(?project?%20or%20?idea?)%20that%20I'd%20like%20to%20speak%20to%20you%20further%20about.%0A%0A&subject=I%20want%20to%20learn%20more%20about%20you're%20business"
                  className="text-primary"
                >
                  info@jetsquared.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
